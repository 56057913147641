<template>
    <div>
        <div class="my-top-right">
            <!--            <p @click="systemClick">-->
            <!--                <img src="../../assets/images/icon11.png" alt="" class="icon11">-->
            <!--            </p>-->
            <!--            <p @click="upClick">-->
            <!--                <img src="../../assets/images/icon12.png" alt="" class="icon11">-->
            <!--            </p>-->
        </div>
        <div class="my_center">
            <div class="my_center_left" @click="onCLickAvatar">
                <div class="issex_div">
                    <img :src="user_infoList.avatar" alt="" class="set_def_tx">
                    <p v-if="user_infoList.gender == 0"></p>
                    <p class="issex nan" v-if="user_infoList.gender == 1">
                        <img src="../../assets/images/nan.png" alt="">
                    </p>
                    <p class="issex nv" v-if="user_infoList.gender == 2">
                        <img src="../../assets/images/nv.png" alt="">
                    </p>
                </div>
                <div class="my_center_center">
                    <p class="my_title"  v-if="!user_infoList.nickname">未登录</p>
                    <p class="my_title" v-else>{{user_infoList.nickname}}</p>
                    <p class="my_subtitle">{{user_infoList.sign?user_infoList.sign:'请设置你的个性签名'}}</p>
                </div>
            </div>
            <div class="my_center_right">
                <p class="right_title"><span>{{base_infoList.read_time?base_infoList.read_time:'0'}}</span>分钟</p>
                <p class="right_subtitle">今日阅读时长</p>
            </div>
        </div>
        <div class="my-bg">
            <p class="my_bg_title" @click="walletClick">我的钱包</p>
            <div class="my_bg_list">
                <div class="bg_list" @click="walletClick">
                    <p class="list_title">{{base_infoList.coin?base_infoList.coin:'0'}}</p>
                    <p class="list_subtitle">书豆余额</p>
                </div>
                <div class="bg_list bg_list1" @click="walletClick">
                    <p class="list_title">{{base_infoList.today_coin?base_infoList.today_coin:'0'}}</p>
                    <p class="list_subtitle">今日书豆</p>
                </div>
                <p class="list_button" @click="withdrawalClick">
                    立即提现
                </p>
            </div>
        </div>
        <div class="vip_bg" @click="VipClick">
            <div>
                <img src="../../assets/images/vip_tit_img.png" alt="" class="vip_tit_img">
                <p class="open-title" v-if="user_infoList.viptime">畅读VIP会员至{{user_infoList.viptime}}到期</p>
                <p class="open-title" v-else>开通会员享受更多福利</p>
            </div>
            <p class="open-text">{{user_infoList.is_vip== '1'?'会员中心':'去开通'}}</p>
        </div>
        <div v-if="base_infoList.list">
            <div class="my_list" @click="systemClick">
                <div class="my_list_left">
                    <p><img src="../../assets/images/icon11.png" alt="" class="icon5"></p>
                    <p class="my_list_title">我的消息</p>
                </div>
                <p>
                    <van-icon name="arrow" color="#BFC2CC"/>
                </p>
            </div>


            <template v-for="item in base_infoList.list[0]">
                <div class="my_list" @click="invitationClick(item)" v-if="item.h5">
                    <div class="my_list_left">
                        <p><img :src="item.icon" alt="" class="icon5"></p>
                        <p class="my_list_title">{{item.name}}</p>
                    </div>
                    <p>
                        <van-icon name="arrow" color="#BFC2CC"/>
                    </p>
                </div>
            </template>
            <p class="xian"></p>
            <template v-for="item in base_infoList.list[1]">
                <div class="my_list" @click="bookClick(item)" v-if="item.h5">
                    <div class="my_list_left">
                        <p><img :src="item.icon" alt="" class="icon5"></p>
                        <p class="my_list_title">{{item.name}}</p>
                    </div>
                    <p>
                        <van-icon name="arrow" color="#BFC2CC"/>
                    </p>
                </div>
            </template>
            <div class="my_list" @click="upClick">
                <div class="my_list_left">
                    <p><img src="../../assets/images/icon12.png" alt="" class="icon5"></p>
                    <p class="my_list_title">设置</p>
                </div>
                <p>
                    <van-icon name="arrow" color="#BFC2CC"/>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "My",
        data() {
            return {
                user_infoList: [],
                base_infoList: [],
                userAgent: true,
                code: '',
            }
        },
        created() {
            this.user_info()
            this.base_info()
            if (/MicroMessenger/.test(window.navigator.userAgent)) {
                //在微信中
                this.userAgent = true
                if (!localStorage.getItem('token')) {
                    this.code = this.GetUrlParam('code')
                    if (this.code) {
                        this.getOpenId(this.code)
                    }
                }
            } else {
                //在浏览器中打开
                this.userAgent = false
            }
        },
        methods: {
            GetUrlParam(name) {//封装方法
                var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
                var r = window.location.search.substr(1).match(reg); //匹配目标参数
                if (r != null) return unescape(r[2]);
                return null; //返回参数值
            },
            onCLickAvatar(){
             if( this.user_infoList){
                 this.upClick();
             }else{
                 this.login();
             }
            },
            // 登录
            WeiXin() {
                //注意事项：回调地址必须要在公众号里进行配置回调地址才会生效
                this.code = this.GetUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
                var local = window.location.href
                if (!this.code) {
                    window.location.href = axios.defaults.baseURL+'/api/wechat_official' +
                        '?redirect_uri=' + encodeURIComponent(local) +
                        '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
                } else {
                    this.getOpenId(this.code) //把code传给后台获取用户信息
                }
            },
            getOpenId(code) { // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
                this.$axios.post('/api/wechat_official_account_login', {
                    code: code
                })
                    .then(res => {
                        if (res.data.data.unregistered == '1') {
                            this.$toast('授权登录')
                            this.$router.push({
                                path: '/replacephone?openid=' + res.data.data.openid,
                            })
                        } else {
                            this.$toast.success('登录成功')
                            localStorage.setItem("token", res.data.data.access_token)
                            localStorage.setItem("openid", res.data.data.openid)
                            this.$nextTick(() => {
                                this.user_info()
                                this.base_info()
                            })
                        }
                    })
            },
            base_info() {
                this.$axios.post('/api/user/base_info')
                    .then(res => {


                        var res = res.data.data
                        this.base_infoList = res
                        if (!this.userAgent) {  //在微信应用内 邀请好友显示
                            this.base_infoList.list[0].splice(this.base_infoList.list[0].findIndex(e => e.id === '1'), 1)
                        }
                    })
            },
            user_info() {
                this.$axios.get('/api/user_info')
                    .then(res => {
                        if(res.data.code==401){
                            localStorage.clear();
                            return ;
                        }
                        this.user_infoList = res.data.data
                    })
            },
            invitationClick(item) {
                if (!localStorage.getItem('token')) {
                    if (this.userAgent) {
                        this.WeiXin()
                    } else {
                        this.$router.push({
                            path: '/login'
                        })
                    }
                    return
                }
                if (item.id == '1') {
                    this.$router.push({
                        path: '/invitation',
                        query: {
                            user_id: this.user_infoList.id
                        }
                    })
                }
                if (item.id == '2') {
                    this.$router.push({
                        path: '/fond'
                    })
                }
            },
            systemClick() {
                if (!localStorage.getItem('token')) {
                    if (this.userAgent) {
                        this.WeiXin()
                    } else {
                        this.$router.push({
                            path: '/login'
                        })
                    }
                    return
                }
                this.$router.push({
                    path: '/system'
                })
            },
            upClick() {
                if (!localStorage.getItem('token')) {
                    if (this.userAgent) {
                        this.WeiXin()
                    } else {
                        this.$router.push({
                            path: '/login'
                        })
                    }
                    return
                }
                this.$router.push({
                    path: '/up'
                })
            },
            login() {
                if (this.userAgent) {
                    this.WeiXin()
                } else {
                    this.$router.push({
                        path: '/login'
                    })
                }
            },
            bookClick(item) {
              if (!localStorage.getItem('token')) {
                    if (this.userAgent) {
                        this.WeiXin()
                    } else {
                        this.$router.push({
                            path: '/login'
                        })
                    }
                    return
                }
                if (item.id == '3') {
                    this.$router.push({
                        path: '/record'
                    })
                }
                if (item.id == '4') {
                    this.$router.push({
                        path: '/system',
                        query: {
                            active: '2'
                        }
                    })
                }
                if (item.id == '5') {
                    this.$router.push({
                        path: '/customer'
                    })
                }
            },
            withdrawalClick() {
                if (!localStorage.getItem('token')) {
                    if (this.userAgent) {
                        this.WeiXin()
                    } else {
                        this.$router.push({
                            path: '/login'
                        })
                    }
                    return
                }
                this.$router.push({
                    path: '/withdrawal'
                })
            },
            walletClick() {
                if (!localStorage.getItem('token')) {
                    if (this.userAgent) {
                        this.WeiXin()
                    } else {
                        this.$router.push({
                            path: '/login'
                        })
                    }
                    return
                }
                this.$router.push({
                    path: '/wallet'
                })
            },
            VipClick() {
                if (!localStorage.getItem('token')) {
                    if (this.userAgent) {
                        this.WeiXin()
                    } else {
                        this.$router.push({
                            path: '/login'
                        })
                    }
                    return
                }
                this.$router.push({
                    path: '/vip'
                })
            },
        }
    }
</script>

<style scoped>
    .open-text {
        padding: 10px 25px;
        background: linear-gradient(45deg, rgba(235, 217, 181, 1) 0%, rgba(218, 190, 143, 1) 100%);
        border-radius: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #303133;
        font-size: 28px;
    }

    .open-title {
        font-size: 22px;
        color: #EBD8B4;
        margin-top: 10px;
    }

    .vip_tit_img {
        width: 118px;
        height: 36px;
    }

    .vip_bg {
        margin: 0 40px 40px;
        padding: 20px 40px;
        background: url("../../assets/images/vip_center_bg.png") no-repeat;
        background-size: cover;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .nan {
        background: #4D77FD;
    }

    .nv {
        background: #fd4e77;
    }

    .issex {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 5px;
        bottom: 5px;
    }

    .issex_div {
        position: relative;
    }

    .issex > img {
        width: 25px;
        height: 25px;

    }

    .bg_list {
        text-align: center;
        width: 200px;
    }

    .bg_list1 {
        width: 200px;
    }

    .list_button {
        width: 180px;
        height: 60px;
        background: rgba(242, 245, 248, 1);
        border-radius: 30px;
        font-size: 28px;
        color: rgba(69, 100, 243, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        /*margin-left: 40px;*/
    }

    .list_subtitle {
        color: #fff;
        font-size: 28px;
    }

    .list_title {
        font-size: 36px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .my_bg_list {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .my_bg_title {
        background: #FD4E77;
        border-radius: 20px 0px 20px 0px;
        color: #fff;
        font-size: 20px;
        width: 120px;
        padding: 10px 0;
        text-align: center;
    }

    .my-bg {
        background: url("../../assets/images/wallet_bg.png") no-repeat;
        background-size: cover;
        height: 140px;
        margin: 40px 40px 40px;

    }

    .xian {
        height: 20px;
        background: #F2F5F8;
        margin-bottom: 50px;
    }

    .my_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 40px;
        margin-bottom: 50px;
    }

    .my_list_title {
        color: #303133;
        font-size: 30px;
        margin-left: 20px;
    }

    .my_list_left {
        display: flex;
        align-items: center;

    }

    .icon5 {
        width: 30px;
        height: 30px;
    }

    .right_subtitle {
        font-size: 24px;
        color: #0A1C33;
        text-align: center;
    }

    .my_center_left {
        display: flex;
        align-items: center;
    }

    .right_title {
        text-align: center;
        font-size: 20px;
        color: #BFC2CC;
        margin-bottom: 40px;
    }

    .right_title span {
        font-size: 36px;
        color: #0A1C33;
        margin: 10px;
    }

    .my_center_center {
        width: 250px;
        margin-left: 40px;
    }

    .my_subtitle {
        font-size: 24px;
        color: #BFC2CC;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .my_title {
        font-size: 36px;
        color: #0A1C33;
        margin-bottom: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .my_center {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
    }

    .set_def_tx {
        width: 120px;
        height: 120px;
        border-radius: 100%;
        background: url("../../assets/images/set_def_tx.png") no-repeat;
        background-size: cover;
        display: inline-block;
    }

    .icon11 {
        width: 40px;
        height: 37px;
        margin-left: 40px;
    }

    .my-top-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 40px;
    }
</style>